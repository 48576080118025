import React, { useState, useCallback } from "react"

import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonButtons,
	IonButton,
	IonIcon,
	IonList,
	IonModal
} from "@ionic/react"
import { add } from "ionicons/icons"

import { useAwait, openDb, useAsyncState, RowSchema } from "../helpers"

import { Row } from "../components/row"
import { AddOrUpdate } from "../components/add-or-update"

const Home: React.FC = () => {
	const title = "Countdowns"
	const [db, dbPromise] = useAwait(() => openDb())
	const [items, setItems] = useAsyncState(() => dbPromise.then((db) => db.getAll("rows")))

	const [addOrUpdate, setAddOrUpdate] = useState<boolean | RowSchema>(false)

	const openAdd = useCallback(() => setAddOrUpdate(true), [])
	const closeAdd = useCallback(() => setAddOrUpdate(false), [])

	const save = useCallback(
		async (row: RowSchema) => {
			if (!db) {
				return
			}

			const key = await db.put("rows", row)

			setItems((items) => {
				if (!items) {
					return
				}

				items = items.slice()

				const index = items.findIndex((item) => item.key === key)

				if (index >= 0) {
					items[index] = row
				} else {
					items.push({
						key,
						...row
					})
				}

				return items
			})
		},
		[db, setItems]
	)

	const deleteItem = useCallback(
		async (item: RowSchema) => {
			if (!db) {
				return
			}

			await db.delete("rows", item.key!)

			setItems((items) => {
				if (!items) {
					return
				}

				items = items.slice()

				const index = items.findIndex(({ key }) => item.key === key)

				items.splice(index, 1)

				return items
			})
		},
		[db, setItems]
	)

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					{/* <IonButtons slot="secondary">
						<IonButton>
							<IonIcon slot="icon-only" icon={cog} />
						</IonButton>
					</IonButtons> */}
					<IonTitle>{title}</IonTitle>
					<IonButtons slot="primary">
						<IonButton onClick={openAdd}>
							<IonIcon slot="icon-only" icon={add} />
						</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<IonModal isOpen={Boolean(addOrUpdate)}>
					<AddOrUpdate
						closeModal={closeAdd}
						save={save}
						row={typeof addOrUpdate === "object" ? addOrUpdate : undefined}
					/>
				</IonModal>
				<IonHeader collapse="condense">
					<IonToolbar>
						<IonTitle size="large">{title}</IonTitle>
					</IonToolbar>
				</IonHeader>

				<IonList>
					{items?.map((props, i) => (
						<Row
							key={i}
							{...props}
							update={() => setAddOrUpdate(props)}
							delete={() => deleteItem(props)}
						/>
					))}
				</IonList>
			</IonContent>
		</IonPage>
	)
}

export default Home
