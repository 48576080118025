import React, { useState, useEffect } from "react"

import { RowSchema, RowUnit, RowUnitKey, RowUnitKeySingularVariants } from "../../helpers"
import {
	IonItemSliding,
	IonItem,
	IonItemOptions,
	IonItemOption,
	IonLabel,
	IonAvatar
} from "@ionic/react"
import { off } from "process"

const Seconds = 1
const Minutes = 60 * Seconds
const Hours = 60 * Minutes
const Days = 24 * Hours
const Weeks = 7 * Days

export type RowProps = RowSchema & {
	delete: () => void
	update: () => void
}

export const Row: React.FC<RowProps> = ({
	update,
	delete: del,

	image,
	text,
	date,
	unit = RowUnit.auto,
	hasTime
}) => {
	const [now, setNow] = useState(() => nowAccountingForOverlap(date, hasTime))

	if (unit & RowUnit.auto) {
		unit = ~(~0 << 7) | RowUnit.auto
	}

	const inPast = date < now

	const units: [RowUnitKey, number][] = []

	let years = yearsBetweenNowAnd(date, hasTime)
	let seconds = Math.round(Math.abs(date.getTime() - now.getTime()) / 1000)

	if (unit & RowUnit.millennia) {
		units.push(["millennia", Math.floor(years / 1000)])

		let nowInNearestMillennium = dateBasedOn(now, {
			year: Math.round(date.getFullYear() / 100) * 100
		})

		years %= 1000
		seconds = Math.round(
			Math.abs(date.getTime() - nowInNearestMillennium.getTime()) / 1000
		)
	}

	if (unit & RowUnit.centuries) {
		units.push(["centuries", Math.floor(years / 100)])

		let nowInNearestCentury = dateBasedOn(now, {
			year: Math.round(date.getFullYear() / 100) * 100
		})

		years %= 100
		seconds = Math.round(Math.abs(date.getTime() - nowInNearestCentury.getTime()) / 1000)
	}

	if (unit & RowUnit.decades) {
		units.push(["decades", Math.floor(years / 10)])

		let nowInNearestDecade = dateBasedOn(now, {
			year: Math.round(date.getFullYear() / 10) * 10
		})

		years %= 10
		seconds = Math.round(Math.abs(date.getTime() - nowInNearestDecade.getTime()) / 1000)
	}

	if (unit & RowUnit.years) {
		units.push(["years", years])

		let nowInNearestYear = dateBasedOn(now, {
			year: date.getFullYear()
		})

		years = 0
		seconds = Math.round(Math.abs(date.getTime() - nowInNearestYear.getTime()) / 1000)
	}

	if (unit & RowUnit.months) {
		let months = monthsBetweenNowAnd(date, hasTime)

		units.push(["months", months - (years + yearsBetweenNowAnd(date, hasTime)) * 12])

		const offset = inPast
			? date.getDate() > now.getDate()
				? 1
				: 0
			: date.getDate() < now.getDate()
			? -1
			: 0

		let nowInNearestMonth = dateBasedOn(now, {
			year: date.getFullYear(),
			month: date.getMonth() + offset
		})

		seconds = Math.round(Math.abs(date.getTime() - nowInNearestMonth.getTime()) / 1000)

		if (text === "Fast ends") {
			console.log(now)
			console.log(date)
		}
	}

	if (unit & RowUnit.weeks) {
		units.push(["weeks", Math.floor(seconds / Weeks)])

		seconds %= Weeks
	}

	if (unit & RowUnit.days) {
		units.push(["days", Math.floor(seconds / Days)])

		seconds %= Days
	}

	if (unit & RowUnit.hours) {
		units.push(["hours", Math.floor(seconds / Hours)])

		seconds %= Hours
	}

	if (unit & RowUnit.minutes) {
		units.push(["minutes", Math.floor(seconds / Minutes)])

		seconds %= Minutes
	}

	if (unit & RowUnit.seconds) {
		units.push(["seconds", seconds])

		seconds = 0
	}

	if (seconds > 0) {
		units[units.length - 1][1] += 0.5
	}

	for (let i = units.length - 1; i >= 0; i--) {
		if (!units[i][1]) {
			units.splice(i, 1)
		}
	}

	if (unit & RowUnit.auto && units.length > 2) {
		units.length = 2
		units[units.length - 1][1] += 0.5
	}

	const unitStrings = units
		.filter(([, a]) => a)
		.map(([key, amount]) => {
			const ceil = Math.ceil(amount)
			const descriptor = ceil === 1 ? RowUnitKeySingularVariants[key] : key

			return key !== "minutes" && key !== "seconds"
				? `${amount % 1 ? `<${ceil}` : amount} ${descriptor}`
				: `${ceil} ${descriptor}`
		}, "")

	useEffect(() => {
		const now = new Date()

		let interval = 0
		let timeout = setTimeout(() => {
			if (hasTime) {
				setNow(nowAccountingForOverlap(date, hasTime))
			}

			interval = window.setInterval(
				() => setNow(nowAccountingForOverlap(date, hasTime)),
				1000
			)
		}, 1000 - now.getMilliseconds())

		return () => {
			if (interval) {
				clearInterval(interval)
			} else {
				clearTimeout(timeout)
			}
		}
	}, [date, hasTime])

	const unitBlocks = unitStrings.map((s, i) => (
		<React.Fragment key={i}>
			{i > 0 && ", "}
			<span style={{ display: "inline-block" }}>{s}</span>
		</React.Fragment>
	))

	return (
		<IonItemSliding>
			<IonItem onClick={update} detail>
				{image && (
					<IonAvatar slot="start">
						<img alt="" src={image} />
					</IonAvatar>
				)}
				<IonLabel className="ion-text-wrap">
					<h2>{text}</h2>
					<h3>
						{unitStrings.length ? (
							<>
								{unitBlocks} {inPast ? "ago" : "to go"}
							</>
						) : (
							`Now`
						)}
					</h3>
					<p>
						{hasTime
							? date
									.toLocaleString()
									.replace(":00 ", " ")
									.replace(/AM|PM/, (s) => s.toLowerCase())
							: date.toLocaleDateString()}
					</p>
				</IonLabel>
			</IonItem>

			<IonItemOptions onIonSwipe={del}>
				<IonItemOption color="danger" expandable onClick={del}>
					Delete
				</IonItemOption>
			</IonItemOptions>
		</IonItemSliding>
	)
}

function nowAccountingForOverlap(date: Date, includingTime: boolean) {
	const now = new Date()

	const secondsAway = Math.round(Math.abs(date.getTime() - now.getTime()) / 1000)

	if (!includingTime) {
		const inTheSameDay = secondsAway <= Days && now.getDate() === date.getDate()
		const inPast = date < now

		return dateBasedOn(now, {
			day: inTheSameDay ? date.getDate() : now.getDate() - (inPast ? 1 : 0),
			hours: inTheSameDay ? date.getHours() : undefined,
			minutes: inTheSameDay ? date.getMinutes() : undefined,
			seconds: inTheSameDay ? date.getSeconds() : undefined,
			milliseconds: inTheSameDay ? date.getMilliseconds() : undefined
		})
	}

	const inTheSameMinute = secondsAway <= Minutes && now.getMinutes() === date.getMinutes()
	const inPast = date < now

	return dateBasedOn(now, {
		minutes: inTheSameMinute ? date.getMinutes() : now.getMinutes() - (inPast ? 1 : 0),
		seconds: inTheSameMinute ? date.getSeconds() : undefined,
		milliseconds: inTheSameMinute ? date.getMilliseconds() : undefined
	})
}

function dateBasedOn(
	date: Date,
	withAdjusted: {
		year?: number
		month?: number
		day?: number
		hours?: number
		minutes?: number
		seconds?: number
		milliseconds?: number
	} = {}
) {
	return new Date(
		withAdjusted.year ?? date.getFullYear(),
		withAdjusted.month ?? date.getMonth(),
		withAdjusted.day ?? date.getDate(),
		withAdjusted.hours ?? date.getHours(),
		withAdjusted.minutes ?? date.getMinutes(),
		withAdjusted.seconds ?? date.getSeconds(),
		withAdjusted.milliseconds ?? date.getMilliseconds()
	)
}

function yearsBetweenNowAnd(date: Date, includingTime: boolean) {
	const now = nowAccountingForOverlap(date, includingTime)

	let years = Math.abs(date.getFullYear() - now.getFullYear())

	if (years !== 0) {
		const nowInYear = dateBasedOn(now, { year: date.getFullYear() })

		// eslint-disable-next-line no-mixed-operators
		if (date > now === nowInYear > date) {
			years -= 1
		}
	}

	return years
}

function monthsBetweenNowAnd(date: Date, includingTime: boolean) {
	const now = nowAccountingForOverlap(date, includingTime)
	const years = yearsBetweenNowAnd(date, includingTime)

	let months = Math.abs(date.getMonth() - now.getMonth())

	if (months !== 0) {
		const nowInMonth = dateBasedOn(now, {
			year: date.getFullYear(),
			month: date.getMonth()
		})

		// eslint-disable-next-line no-mixed-operators
		if (date > now === nowInMonth > date) {
			months -= 1
		}
	}

	return years * 12 + months
}
