import React, { useCallback, useState, useMemo, useRef } from "react"

import {
	IonButton,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonButtons,
	IonIcon,
	IonContent,
	IonInput,
	IonList,
	IonItem,
	IonLabel,
	IonSelect,
	IonSelectOption,
	IonAlert,
	IonAvatar,
	IonItemSliding,
	IonItemOptions,
	IonItemOption
} from "@ionic/react"
import { close, checkmark } from "ionicons/icons"

import { RowSchema, RowUnit, maskToArray, RowUnitKey } from "../../helpers"
import { IonSelectDivider } from "../ion-select-divider/IonSelectDivider"

import * as styles from "./AddOrUpdate.scss"

export type AddOrUpdateProps = {
	row?: RowSchema
	closeModal: () => void
	save: (row: RowSchema) => void
}

const imageSize = 64

export const AddOrUpdate: React.FC<AddOrUpdateProps> = ({ row, closeModal, save }) => {
	const title = row != null ? "Update" : "New"

	const [image, setImage] = useState(row?.image)
	const [text, setText] = useState(row?.text)
	const [date, setDate] = useState(row?.date)
	const [time, setTime] = useState(row?.hasTime ? row?.date : undefined)
	const [unit, setUnits] = useState(row?.unit)

	const listRef = useRef<HTMLIonListElement>(null)

	const [showCloseConfirm, setShowCloseConfirm] = useState(false)

	const closeWithoutSaving = useCallback(() => {
		if (
			image !== row?.image ||
			text !== row?.text ||
			date?.toLocaleDateString() !== row?.date.toLocaleDateString() ||
			(row?.hasTime
				? row?.date.toLocaleTimeString() !== time?.toLocaleTimeString()
				: time) ||
			unit !== row?.unit
		) {
			setShowCloseConfirm(true)
		} else {
			closeModal()
		}
	}, [image, row, text, date, time, unit, closeModal])

	const saveAndClose = useCallback(() => {
		if (!text || !date) {
			return
		}

		save({
			...row,
			image,
			text,
			unit,
			date: new Date(
				date.getFullYear(),
				date.getMonth(),
				date.getDate(),
				time?.getHours() ?? 0,
				time?.getMinutes() ?? 0
			),
			hasTime: time != null
		})

		closeModal()
	}, [text, date, save, row, image, unit, time, closeModal])

	const fileInputRef = useRef<HTMLInputElement>(null)
	const clickFileInput = useCallback(() => {
		fileInputRef.current?.click()
	}, [])

	const updateImage = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.currentTarget.files?.[0]

		if (file) {
			setImage(await toBase64(file))
		} else {
			setImage(undefined)
		}
	}, [])

	const clearImage = useCallback(() => {
		listRef.current?.closeSlidingItems()
		setImage(undefined)
	}, [])

	const updateText = useCallback(
		({ detail: { value } }: { detail: { value: string | null | undefined } }) => {
			setText(value || "")
		},
		[]
	)

	const updateDate = useCallback(
		({ detail: { value } }: { detail: { value: string | undefined | null } }) => {
			console.log(value)

			if (value) {
				const parts = value.split("-").map((n) => parseInt(n.replace(/^0+/, ""), 10)) as [
					number,
					number,
					number
				]

				parts[1] -= 1

				setDate(new Date(...parts))
			} else {
				setDate(undefined)
			}
		},
		[]
	)

	const updateTime = useCallback(
		({ detail: { value } }: { detail: { value: string | undefined | null } }) => {
			console.log(value)

			if (value) {
				setTime(new Date(`2020-01-01T${value}`))
			} else {
				setTime(undefined)
			}
		},
		[]
	)

	const clearTime = useCallback(() => {
		listRef.current?.closeSlidingItems()
		setTime(undefined)
	}, [])

	const updateSelectDisplay = useCallback((el: HTMLIonSelectElement | null) => {
		if (el) {
			const root = el.shadowRoot

			if (root && !root.querySelector(":root > style")) {
				const style = document.createElement("style")

				style.innerText = ".select-text { white-space: normal !important; }"

				root.appendChild(style)
			}
		}
	}, [])

	const updateUnits = useCallback(
		({ detail: { value } }: { detail: { value: RowUnit[] | null } }) => {
			if (value?.length) {
				setUnits(value.reduce((t, n) => t | n))
			} else {
				setUnits(undefined)
			}
		},
		[]
	)

	const unitArray = useMemo(() => (unit ? maskToArray(unit) : undefined), [unit])

	return (
		<>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="secondary">
						<IonButton onClick={closeWithoutSaving}>
							<IonIcon slot="icon-only" icon={close} />
						</IonButton>
					</IonButtons>
					<IonTitle>{title}</IonTitle>
					<IonButtons slot="primary">
						<IonButton onClick={saveAndClose} disabled={!text || !date}>
							<IonIcon slot="icon-only" icon={checkmark} />
						</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<IonHeader collapse="condense">
					<IonToolbar>
						<IonTitle size="large">{title}</IonTitle>
					</IonToolbar>
				</IonHeader>

				<IonList ref={listRef}>
					<IonItemSliding disabled={!image}>
						<IonItem detail button onClick={clickFileInput}>
							<IonLabel>
								Image{" "}
								<small>
									<i>(optional)</i>
								</small>
							</IonLabel>
							{image ? (
								<IonAvatar slot="end">
									<img alt="" src={image} />
								</IonAvatar>
							) : (
								<IonLabel className={styles.placeholder}>Select an image</IonLabel>
							)}
							<input
								type="file"
								accept="image/*"
								ref={fileInputRef}
								style={{
									opacity: 0,
									position: "absolute",
									pointerEvents: "none"
								}}
								tabIndex={-1}
								onChange={updateImage}
							/>
						</IonItem>

						<IonItemOptions onIonSwipe={clearImage}>
							<IonItemOption expandable color="danger" onClick={clearImage}>
								Remove image
							</IonItemOption>
						</IonItemOptions>
					</IonItemSliding>
					<IonItem>
						<IonLabel>Heading</IonLabel>
						<IonInput
							type="text"
							placeholder="Enter some text"
							value={text}
							autocapitalize="words"
							style={{
								textAlign: "right",
								"--placeholder-color": "var(--color)",
								"--placeholder-opacity": "0.4"
							}}
							onIonChange={updateText}
						/>
					</IonItem>
					<IonItem detail>
						<IonLabel>Date</IonLabel>
						<IonInput
							type="date"
							slot="end"
							style={{ textAlign: "right" }}
							value={date?.toISOString().slice(0, 10)}
							onIonChange={updateDate}
						/>
						{!date && (
							<IonLabel className={styles.placeholder} slot="end">
								Select a date
							</IonLabel>
						)}
					</IonItem>
					<IonItemSliding disabled={!time}>
						<IonItem detail>
							<IonLabel>
								Time{" "}
								<small>
									<i>(optional)</i>
								</small>
							</IonLabel>
							<IonInput
								type="time"
								slot="end"
								style={{ textAlign: "right" }}
								value={
									time ? `${pad(time.getHours())}:${pad(time.getMinutes())}` : undefined
								}
								onIonChange={updateTime}
							/>
							{!time && (
								<IonLabel className={styles.placeholder} slot="end">
									Select a time
								</IonLabel>
							)}
						</IonItem>

						<IonItemOptions onIonSwipe={clearTime}>
							<IonItemOption expandable color="danger" onClick={clearTime}>
								Remove time
							</IonItemOption>
						</IonItemOptions>
					</IonItemSliding>
					<IonItem>
						<IonLabel>Units</IonLabel>
						<IonSelect
							ref={updateSelectDisplay}
							multiple
							placeholder="auto"
							style={{ "--placeholder-opacity": "1" }}
							onIonChange={updateUnits}
							value={unitArray}
						>
							{RowUnitKey.map((w) => {
								return (
									<React.Fragment key={w}>
										{w === "decades" && <IonSelectDivider />}
										<IonSelectOption value={RowUnit[w]}>{w}</IonSelectOption>
									</React.Fragment>
								)
							})}
						</IonSelect>
					</IonItem>
				</IonList>

				<IonAlert
					isOpen={showCloseConfirm}
					header="There are unsaved changes"
					message="If you close now, you'll lose any changes that you've made so far."
					buttons={[
						{
							text: "Lose changes",
							role: "destructive",
							handler: closeModal
						},
						{
							text: "Cancel",
							role: "cancel",
							handler: () => setShowCloseConfirm(false)
						}
					]}
				/>
			</IonContent>
		</>
	)
}

async function toBase64(file: File) {
	return new Promise<string>((resolve) => {
		const reader = new FileReader()

		reader.onloadend = () => {
			const result = reader.result?.toString()

			if (result) {
				const img = document.createElement("img")

				img.onload = () => {
					resolve(resize(img, imageSize * 3))
				}

				img.src = result
			}
		}

		reader.readAsDataURL(file)
	})
}

function resize(img: HTMLImageElement, size: number) {
	const height = img.naturalHeight
	const width = img.naturalWidth

	const centerHorizontally = width >= height
	const canvas = document.createElement("canvas")
	const ctx = canvas.getContext("2d")!

	canvas.width = size
	canvas.height = size

	if (centerHorizontally) {
		const scale = size / height
		const leftover = width * scale - size

		ctx.drawImage(img, -leftover / 2, 0, size + leftover, size)
	} else {
		const scale = size / width
		const leftover = height * scale - size

		ctx.drawImage(img, 0, -leftover / 2, size, size + leftover)
	}

	return canvas.toDataURL()
}

function pad(num: number, to = 2) {
	return `00000000000${num}`.slice(-1 * to)
}
