import { useRef, useState, useMemo } from "react"

import { Tuple } from "./Functions"

export function useConstructor(cb: () => void) {
	const didRun = useRef(false)

	if (!didRun.current) {
		didRun.current = true

		cb()
	}
}

/** Use a state variable that is initialized the first time asynchronously, then can be reset at any time with a normal setter. */
export function useAsyncState<T>(cb: () => Promise<T>) {
	const [value, setValue] = useState<T>()

	useConstructor(async () => setValue(await cb()))

	return Tuple(value, setValue)
}

/** Use a variable that is initialized asynchronously. */
export function useAwait<T>(cb: () => Promise<T>) {
	const [value, setValue] = useState<T>()

	// eslint-disable-next-line
	const promise = useMemo(() => cb().then((v) => (setValue(v), v)), [])

	return Tuple(value, promise)
}
