import { DBSchema, openDB } from "idb"
import { filtermap } from "./Functions"

export enum RowUnit {
	years = 1 << 0,
	months = 1 << 1,
	weeks = 1 << 2,
	days = 1 << 3,
	hours = 1 << 4,
	minutes = 1 << 5,
	seconds = 1 << 6,
	decades = 1 << 7,
	centuries = 1 << 8,
	millennia = 1 << 9,
	auto = 1 << 10
}

export type RowUnitKey = Exclude<keyof typeof RowUnit, "auto">
export const RowUnitKey = Object.keys(RowUnit).filter(
	(key) => key !== "auto" && !/^[0-9]+$/.test(key)
) as RowUnitKey[]

export const RowUnitKeySingularVariants = {
	years: "year",
	months: "month",
	weeks: "week",
	days: "day",
	hours: "hour",
	minutes: "minute",
	seconds: "second",
	decades: "decade",
	centuries: "century",
	millennia: "millennium"
}

export function maskToArray(unit: RowUnit) {
	return filtermap(RowUnitKey, (key) => unit & RowUnit[key] && RowUnit[key])
}

export interface RowSchema {
	key?: number

	image?: string
	text: string
	date: Date
	unit?: RowUnit

	hasTime: boolean
}

export interface Schema extends DBSchema {
	rows: {
		value: RowSchema
		key: number
	}
}

export async function openDb() {
	return openDB<Schema>("until/since", 1, {
		upgrade: (db) => {
			db.createObjectStore("rows", { autoIncrement: true, keyPath: "key" })
		}
	})
}
