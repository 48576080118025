import { FalsibleList, NestableList, NonFalsible, Stringlike } from "./Types"

declare global {
	interface ObjectConstructor {
		keys<T, K extends keyof T>(o: T): Stringlike<K>[]
		values<T, K extends keyof T>(o: T): T[K][]
		entries<T, K extends keyof T>(o: T): [Stringlike<K>, T[K]][]
	}
}

export interface JoinOptions {
	separator?: string
}

/**
 * Automatically resolve a nested optional array of strings into a single string using the passed separator. If using all literal strings, just use a literal class (it will save cycles).
 *
 * @param options The options containing the separator to use.
 * @param potentials The potential classes.
 * @returns The joined string of all the items.
 */
export function join(options: JoinOptions, ...potentials: FalsibleList<string>[]): string
/**
 * Automatically resolve a nested optional array of strings into a single space separated string. If using all literal strings, just use a literal class (it will save cycles).
 *
 * @param potentials The potential classes.
 * @returns The joined string of all the items.
 */
export function join(...potentials: FalsibleList<string>[]): string

export function join(
	options: JoinOptions | FalsibleList<string>,
	...potentials: FalsibleList<string>[]
): string {
	const optionsWerePassed = Boolean(
		options && typeof options === "object" && !Array.isArray(options)
	)

	const { separator = " " } = (optionsWerePassed ? options : {}) as JoinOptions

	if (!optionsWerePassed) {
		potentials.unshift(options as FalsibleList<string>)
	}

	const values = flatfilter(potentials)

	return values.length > 0 ? values.reduce((trans, next) => trans + separator + next) : ""
}

/**
 * Return the passed array, or wrap the item in an array.
 *
 * @template Type The type of the elements in the array.
 * @param array The arary or item to wrap in an array.
 * @returns The passed item itself if it already was an array, or the item wrapped in an array.
 */
export function wrap<Type>(array: Type | Type[]): Type[] {
	return Array.isArray(array) ? array : [array]
}

/**
 * Filter out all falsy values from the passed array.
 *
 * @template Type The type of the elements in the array.
 * @param array The array (or single item) to filter.
 * @returns A version of the passed array with falsible items filtered out.
 */
export function filter<Type>(array: Type | Type[]) {
	return wrap(array).filter(Boolean) as NonFalsible<Type>[]
}

/**
 * Map a single item or an array of items to another type.
 *
 * @template Type The type of the elements in the array.
 * @template Result The type that results from the mapper.
 * @param array The array of items (or single item) to map.
 * @param mapper The mapper function.
 * @returns A version of the passed array with its values mapped.
 */
export function map<Type, Result>(
	array: Type | Type[],
	mapper: (item: Type, index: number) => Result
) {
	return wrap(array).map(mapper)
}

/**
 * Completely flatten a nestable array.
 *
 * @template Type The type of the elements in the array.
 * @param array The array to completely flatten.
 * @returns A flattened version of the passed array.
 */
export function flat<Type>(array: NestableList<Type>) {
	return wrap(array).flat(Infinity) as Type[]
}

/**
 * Completely flatten a nestable array, then map it with a mapper function.
 *
 * @template Type The type of the elements in the array.
 * @template Result The type that results from the mapper.
 * @param array The array to completely flatten then map.
 * @param mapper The mapper function to use.
 * @returns A flattened version of the passed array with items mapped.
 */
export function flatmap<Type, Result>(
	array: NestableList<Type>,
	mapper: (item: Type, index: number) => Result
) {
	return map(flat(array), mapper)
}

/**
 * Completely flatten a nestable array, then filter out falsey values.
 *
 * @template Type The type of the elements in the array.
 * @param array The array to completely flatten, then from which to filter out falsey values.
 * @returns A flattened version of the passed array with falsible items filtered out.
 */
export function flatfilter<Type>(array: NestableList<Type>) {
	return filter(flat(array))
}

/**
 * Map an array, then filter out falsey values.
 *
 * @template Type The type of the elements in the array.
 * @template Result The type that results from the mapper.
 * @param array The array to map with the passed mapper, then from which to filter out falsey values.
 * @param mapper The mapper function to use.
 * @returns A version of the passed array with its items mapped and falsible items filtered out.
 */
export function filtermap<Type, Result>(
	array: Type | Type[],
	mapper: (item: Type, index: number) => Result
) {
	return filter(map(array, mapper))
}

/**
 * Completely flatten an array, map with the passed mapper, then filter out falsey values.
 *
 * @template Type The type of the elements in the array.
 * @template Result The type that results from the mapper.
 * @param array The array to completely flatten, map with the passed mapper, then from which to filter out falsey values.
 * @param mapper The mapper function to use.
 * @returns A flattened version of the passed array with its items mapped and falsible items filtered out.
 */
export function flatfiltermap<Type, Result>(
	array: NestableList<Type>,
	mapper: (item: Type, index: number) => Result
) {
	return filtermap(flat(array), mapper)
}

/**
 * Return the passed values as a tuple.
 *
 * @template Tuple The type of the tuple created by the passed in values.
 * @param values The values to inject into the tuple.
 * @returns A tuple of the passed values.
 */
export function Tuple<Tuple extends unknown[]>(...values: Tuple) {
	return values
}
